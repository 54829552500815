<div class="holder">
  <h1>Veri Sorumlusu Başvuru Formu</h1>
  <p>Başvurularınızı ekteki formu kullanarak tarafımıza ulaştırabilirsiniz.</p>
  <br />
  <p>
    <a href="/assets/bilgesin-veri-sorumlusu-basvuru-formu.pdf" target="_blank">
      Başvuru formuna git
    </a>
  </p>
</div>
