<ng-template [ngIf]="conf">
  <div class="holder {{ conf.key }}">
    <div class="inner-holder">
      <div class="logo-holder">
        <img [src]="logoSrc" [alt]="conf.key" />
      </div>

      <div class="lp-top-left">
        <img src="/assets/lp/{{ conf.key }}/top-left-thanks.png" />
      </div>
      <div class="lp-top-right">
        <img src="/assets/lp/{{ conf.key }}/top-right-thanks.png" />
      </div>

      <ng-template [ngIf]="navState?.landingPageKey">
        <div class="text-holder" *ngIf="navState.landingPageKey === 'eker'">
          <b
            >Tebrikler, Eker Online Market'ten
            <a href="market.eker.com" target="_blank">(market.eker.com)</a> 500
            TL ve üzeri alışverişlerde geçerli %5 ek indirim kazandınız. </b
          ><br />
          <b>İşte indirim kodunuz: BILGE5 </b><br />
          Kampanyadan yararlanmak için Ödeme Bilgilerim sayfasında "İndirim
          kuponu" alanına BILGE5 yazmanız yeterlidir. Haydi alışverişe başlayın.
        </div>

        <div class="text-holder" *ngIf="navState.landingPageKey === 'sportive'">
          <b
            >Tebrikler. Sportive'den 3500 TL ve üzeri alışverişlerde geçerli 200
            TL ek indirim kazandınız. </b
          ><br />
          <b>İşte indirim kodunuz: {{ navState.discountCode }} </b><br />
          İndirim kodunuzu
          <a href="https://www.sportive.com.tr" target="_blank">
            www.sportive.com.tr
          </a>
          ve Sportive Mobil Uygulamasında kullanabilirsiniz. İndirim kodunuzu
          sepeti onaylamadan "İndirim Kodu" yazan alana girebilirsiniz. Haydi
          alışverişe başlayın.
        </div>

        <div class="text-holder" *ngIf="navState.landingPageKey === 'scooter'">
          <b>
            Tebrikler, Scooter Ayakkabı web sitesinden (
            <a
              href="http://www.scooter.com.tr"
              target="_blank"
              rel="noreferrer"
            >
              www.scooter.com.tr
            </a>
            ) ilave %10 indirim kazandınız.
          </b>
          <br />
          <b>İşte indirim kodunuz: SCOOTER10 </b><br />
          Kampanyadan yararlanmak için indirim kodunuzu, alışverişi tamamlamadan
          "Hediye Çeki" alanına yazmanız yeterlidir. Haydi aşağıdaki bağlantıya
          tıklayarak indirimli alışverişe başlayın.
        </div>
      </ng-template>

      <div class="text-holder" *ngIf="navState.landingPageKey === 'elbisebul'">
        <b>
          Tebrikler, Elbisebul web sitesinden (
          <a href="https://www.elbisebul.com" target="_blank">
            www.elbisebul.com
          </a>
          ) %10 ilave indirim kazandınız.
        </b>
        <br />
        <b>İşte indirim kodunuz: B10 </b><br />
        Kampanyadan yararlanmak için indirim kodunuzu, sepet sayfasında,
        "İndirim Kodu" yazan alana girebilirsiniz. Haydi aşağıdaki bağlantıya
        tıklayarak indirimli alışverişe başlayın.
      </div>

      <div class="text-holder" *ngIf="navState.landingPageKey === 'yasomi'">
        <b>
          Tebrikler, Yasomi web sitesinden (
          <a href="https://www.yasomi.com.tr" target="_blank">
            www.yasomi.com.tr
          </a>
          ) %3 ilave indirim kazandınız.
        </b>
        <br />
        <b>İşte indirim kodunuz: YSM3 </b><br />
        Kampanyadan yararlanmak için, üye olarak indirim kodunuzu ilgili alana
        yazabilirsiniz. İndirim
        <span
          (click)="productLinksModalShowing = true"
          class="product-links-modal-button"
        >
          seçili ürünlerde</span
        >
        31.12.2024'e kadar geçerlidir. Haydi aşağıdaki bağlantıya tıklayarak
        indirimli alışverişe başlayın.
      </div>

      <div class="text-holder" *ngIf="navState.landingPageKey === 'boost'">
        <b>
          Tebrikler, anketiniz başarıyla tamamlandı ve Boost Oxygen’den
          <a href="https://www.boostoxygen.com.tr" target="_blank">
            www.boostoxygen.com.tr
          </a>
          %15 ilave indirim kazandınız.
        </b>
        <br />
        <b>İşte indirim kodunuz: Oxygen4u </b><br />
        Kampanyadan faydalanmak için, üye olduktan sonra "Kupon kullan" bölümünü
        seçerek kodunuzu girmeniz yeterlidir. İndirim Haydi aşağıdaki bağlantıya
        tıklayarak indirimli alışverişe başlayın.
      </div>

      <div class="text-holder" *ngIf="navState.landingPageKey === 'muhiku'">
        <b>
          Tebrikler, anketiniz başarıyla tamamlandı ve Muhiku web sitesinden (
          <a href="https://muhiku.com" target="_blank">muhiku.com</a>
          ) %10 ek indirim kazandınız.
        </b>
        <br />
        <b>İşte indirim kodunuz: bilgesin10</b><br />
        Kampanyadan faydalanmak için, «Kupon» bölümüne kodunuzu girmeniz
        yeterlidir. Haydi alışverişe başlayın.
      </div>

      <div class="text-holder" *ngIf="navState.landingPageKey === 'mydukkan'">
        <b>
          Tebrikler, anketiniz başarıyla tamamlandı ve Mydukkan web sitesinden (
          <a href="https://mydukkan.co" target="_blank">mydukkan.co</a>
          ) %10 ek indirim kazandınız.
        </b>
        <br />
        <b>İşte indirim kodunuz: bilgesin10</b><br />
        Kampanyadan faydalanmak için, «Kupon» bölümüne kodunuzu girmeniz
        yeterlidir. Haydi alışverişe başlayın.
      </div>

      <div *ngIf="!navState?.landingPageKey" style="text-align: center">
        <b>Bu ekranı görüntülemek için lütfen anketi doldurunuz.</b>
      </div>

      <div class="close-dialog">
        <app-button
          type="link opposite {{ conf.key }}"
          [text]="linkButtonText"
          (click)="goLink()"
        >
        </app-button>
      </div>
    </div>
  </div>
</ng-template>

<app-product-links-modal
  [(visible)]="productLinksModalShowing"
  [companyId]="_yasomiCompanyId"
  *ngIf="conf?.key === 'yasomi'"
></app-product-links-modal>
