import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { take } from "rxjs/operators";
import { tatilAnketiCompanyId } from "src/app/helpers/helpers";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-admin-user-infos",
  templateUrl: "./admin-user-infos.component.html",
  styleUrls: ["./admin-user-infos.component.scss"],
})
export class AdminUserInfosComponent implements OnInit {
  userInfos = [
    {
      userPhone: "",
      gender: "",
      hasGenderAsked: false,
      city: "",
      hasCityAsked: false,
      age: "",
      hasAgeAsked: false,
      shoppedBefore: "",
      hasShoppedBeforeAsked: false,
      workingStatus: "",
      hasWorkingStatusAsked: false,
      kvkkApproval: "",
      createdOn: new Date(),
      userId: "",
      lastSixMonthsShoppingCount: "",
      lastSixMonthsShoppingQuestionTitle: "",
      hasLastSixMonthsShoppingCountAsked: false,
      userPhoneMasked: false,
    },
  ];
  groupedUserInfos = [];

  booleans = {
    hasGenderAsked: false,
    hasCityAsked: false,
    hasShoppedBeforeAsked: false,
    hasWorkingStatusAsked: false,
    hasLastSixMonthsShoppingCountAsked: false,
    hasAgeAsked: false,
  };

  constructor(private http: HttpClient, private userService: UserService) {}

  ngOnInit(): void {
    this.http
      .get("/getAdminUserInfos")
      .pipe(take(1))
      .subscribe((res: any) => {
        this.userInfos = res.map((m) => ({
          ...m,
          createdOn: new Date(m.createdOn),
        }));
        this.handleBooleans();
        this.groupByUserAndSelectLatest();
      });
  }

  sortItems(): void {
    this.userInfos.sort(
      (a, b) => b.createdOn.getTime() - a.createdOn.getTime()
    );
  }

  groupByUserAndSelectLatest(): void {
    this.sortItems();

    const user = this.userService.getUser();
    if (user.companyId !== tatilAnketiCompanyId) {
      const latestPerUser = this.userInfos.reduce((acc, current) => {
        if (
          !acc[current.userId] ||
          acc[current.userId].createdOn < current.createdOn
        ) {
          acc[current.userId] = current;
        }
        return acc;
      }, {});

      this.groupedUserInfos = Object.values(latestPerUser);
      this.groupedUserInfos = this.groupedUserInfos.map((m) => ({
        ...m,
        userFillCount: this.userInfos.filter((u) => u.userId === m.userId)
          .length,
      }));
    } else {
      this.groupedUserInfos = this.userInfos.map((m) => ({
        ...m,
        userFillCount: 1,
      }));
    }
  }

  handleBooleans() {
    for (const key of Object.keys(this.booleans)) {
      if (typeof this.booleans[key] === "undefined") {
        continue;
      }

      this.booleans[key] = this.propBoolean(key);
    }
  }

  propBoolean(propKey: string) {
    if (!this.userInfos.length) {
      return false;
    }

    let show = false;
    for (const item of this.userInfos) {
      show = item[propKey] ?? false;
      if (show) {
        break;
      }
    }
    return show;
  }

  propValue(propKey: string) {
    return this.userInfos?.[0]?.[propKey];
  }
}
