<div class="holder">
  <h1>Kullanıcı Raporları</h1>

  <div class="filter">
    <div class="row">
      <div class="form-control">
        <label for="">Şirket</label>
        <select [(ngModel)]="companyId" (change)="isReportReady = false">
          <option value="">Hepsi</option>
          <option [value]="item._id" *ngFor="let item of companies">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="form-control">
        <label for="">Tarih Aralığı</label>
        <input
          type="text"
          ngxDaterangepickerMd
          [(ngModel)]="selectedDate"
          class="form-control"
          (change)="isReportReady = false"
          [showClearButton]="true"
        />
      </div>
      <div class="form-control" style="padding-top: 30px">
        <app-button text="Raporu Getir" (click)="getReport()"></app-button>
      </div>
    </div>
  </div>

  <br />

  <div class="loader-container" *ngIf="loading">
    <app-loader></app-loader>
  </div>

  <div *ngIf="isReportReady">
    <div class="excel-download-container">
      <app-button text="Excel İndir" (click)="downloadExcel()"></app-button>
    </div>

    <div class="data-holder">
      <div class="table-holder">
        <table>
          <thead>
            <tr>
              <th>Telefon No</th>
              <th>Anket Adı</th>
              <th>Tarih</th>
              <th>Şirket</th>
              <th>Ticari İleti</th>
              <th>Açık Rıza Onayı</th>
              <th>Ret</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of data">
              <td>{{ item.phoneNumber }}</td>
              <td>{{ item.surveyName }}</td>
              <td>{{ item.createdOn | date : "dd.MM.YYYY HH:mm" }}</td>
              <td>{{ item.companyName }}</td>
              <td>{{ ticariIletiText(item) }}</td>
              <td>{{ item.openConsentAgreement ? "Var" : "Yok" }}</td>
              <td>{{ retText(item) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
