<app-header *ngIf="isLayout"></app-header>
<router-outlet></router-outlet>
<app-footer *ngIf="isLayout"></app-footer>
<a
  href="https://wa.me/905360897798?text={{ msgText }}"
  target="_blank"
  class="wp-button"
>
  <img src="/assets/images/whatsapp.svg" alt="Whatsapp" />
</a>
