import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CampaignDetailComponent } from "./pages/campaign-detail/campaign-detail.component";
import { SurveyComponent } from "./pages/survey/survey.component";
import { HomeComponent } from "./pages/home/home.component";
import { LoginComponent } from "./pages/login/login.component";
import { NedenBilgesinComponent } from "./pages/neden-bilgesin/neden-bilgesin.component";
import { NasilKazanirimComponent } from "./pages/nasil-kazanirim/nasil-kazanirim.component";
import { AdminComponent } from "./pages/admin/admin.component";
import { EmailVerificationSuccessComponent } from "./pages/email-verification-success/email-verification-success.component";
import { FaydaliBilgilerComponent } from "./pages/faydali-bilgiler/faydali-bilgiler.component";
import { GizlilikPolitikasiComponent } from "./pages/gizlilik-politikasi/gizlilik-politikasi.component";
import { HakkimizdaComponent } from "./pages/hakkimizda/hakkimizda.component";
import { IletisimComponent } from "./pages/iletisim/iletisim.component";
import { IsOrtagimizOlmakIsterMisinizComponent } from "./pages/is-ortagimiz-olmak-ister-misiniz/is-ortagimiz-olmak-ister-misiniz.component";
import { KariyerComponent } from "./pages/kariyer/kariyer.component";
import { KisiselVerilerinKorunmasiComponent } from "./pages/kisisel-verilerin-korunmasi/kisisel-verilerin-korunmasi.component";
import { KullanimKosullariComponent } from "./pages/kullanim-kosullari/kullanim-kosullari.component";
import { MusteriKisiselVerileriAydinlatmaMetniComponent } from "./pages/musteri-kisisel-verileri-aydinlatma-metni/musteri-kisisel-verileri-aydinlatma-metni.component";
import { SizeOzelComponent } from "./pages/size-ozel/size-ozel.component";
import { FacebookPrivacyComponent } from "./pages/facebook-privacy/facebook-privacy";
import { ForgattenComponent } from "./pages/login/forgatten/forgatten.component";
import { AdminCompanyInfoComponent } from "./pages/admin-company-info/admin-company-info.component";
import { AdminNewSurweyComponent } from "./pages/admin-new-surwey/admin-new-surwey.component";
import { AdminQuestionsComponent } from "./pages/admin-questions/admin-questions.component";
import { AdminReportsComponent } from "./pages/admin-reports/admin-reports.component";
import { AdminStatisticsComponent } from "./pages/admin-statistics/admin-statistics.component";
import { RootCompaniesComponent } from "./pages/root-companies/root-companies.component";
import { RootSectorsComponent } from "./pages/root-sectors/root-sectors.component";
import { RootCustomersComponent } from "./pages/root-customers/root-customers.component";
import { RootFormsComponent } from "./pages/root-forms/root-forms.component";
import { AdminVerifyDiscountCodeComponent } from "./pages/admin-verify-discount-code/admin-verify-discount-code.component";
import { UserEarningsComponent } from "./pages/user-earnings/user-earnings.component";
import { BizdenHaberlerComponent } from "./pages/bizden-haberler/bizden-haberler.component";
import { UnderConstructionComponent } from "./pages/under-construction/under-construction.component";
import { AdminSurveyDiscountCodesComponent } from "./pages/admin-survey-discount-codes/admin-survey-discount-codes.component";
import { RootBlogsComponent } from "./pages/root-blogs/root-blogs.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogDetailComponent } from "./pages/blog-detail/blog-detail.component";
import { AdminSurveySmsTextComponent } from "./pages/admin-survey-sms-text/admin-survey-sms-text.component";
import { RootHomeSurveySortingComponent } from "./pages/root-home-survey-sorting/root-home-survey-sorting.component";
import { RootUserReportsComponent } from "./pages/root-user-reports/root-user-reports.component";
import { AcikRizaComponent } from "./pages/acik-riza/acik-riza.component";
import { RootCompanyFormCouponsComponent } from "./pages/root-company-form-coupons/root-company-form-coupons.component";
import { AdminUserInfosComponent } from "./pages/admin-user-infos/admin-user-infos.component";
import { ForBrandsComponent } from "./pages/for-brands/for-brands.component";
import { RootSearchLogsComponent } from "./pages/root-search-logs/root-search-logs.component";
import { AdminPazanovaComponent } from "./pages/admin-pazanova/admin-pazanova.component";
import { SurveyThanksComponent } from "./pages/survey-thanks/survey-thanks.component";
import { YonetimEkibimizComponent } from "./pages/yonetim-ekibimiz/yonetim-ekibimiz.component";
import { TicariIletiComponent } from "./pages/ticari-ileti/ticari-ileti.component";
import { PazarlamaAcikRizaComponent } from "./pages/pazarlama-acik-riza/pazarlama-acik-riza.component";
import { VeriSorumlusuBasvuruFormuComponent } from "./pages/veri-sorumlusu-basvuru-formu/veri-sorumlusu-basvuru-formu.component";

const routes: Routes = [
  { path: "", component: HomeComponent },
  { path: "ticari-ileti", component: TicariIletiComponent },
  { path: "pazarlama-acik-riza", component: PazarlamaAcikRizaComponent },
  { path: "detail", component: CampaignDetailComponent },
  { path: "size-ozel", component: SizeOzelComponent },
  { path: "neden-bilgesin", component: NedenBilgesinComponent },
  { path: "nasil-kazanirim", component: NasilKazanirimComponent },
  { path: "markalar-icin", component: ForBrandsComponent },
  { path: "yonetim-ekibimiz", component: YonetimEkibimizComponent },
  { path: "faydali-bilgiler", component: FaydaliBilgilerComponent },
  { path: "gizlilik-politikasi", component: GizlilikPolitikasiComponent },
  { path: "hakkimizda", component: HakkimizdaComponent },
  { path: "facebook-privacy", component: FacebookPrivacyComponent },
  { path: "iletisim", component: IletisimComponent },
  {
    path: "is-ortagimiz-olmak-ister-misiniz",
    component: IsOrtagimizOlmakIsterMisinizComponent,
  },
  { path: "kariyer", component: KariyerComponent },
  {
    path: "kisisel-verilerin-korunmasi",
    component: KisiselVerilerinKorunmasiComponent,
  },
  {
    path: "veri-sorumlusu-basvuru-formu",
    component: VeriSorumlusuBasvuruFormuComponent,
  },
  { path: "kullanim-kosullari", component: KullanimKosullariComponent },
  { path: "acik-riza", component: AcikRizaComponent },
  {
    path: "musteri-kisisel-verileri-aydinlatma-metni",
    component: MusteriKisiselVerileriAydinlatmaMetniComponent,
  },
  { path: "survey", component: SurveyComponent },
  { path: "login", component: LoginComponent },
  { path: "forgot-password/:type", component: ForgattenComponent },
  {
    path: "admin",
    component: AdminComponent,
    children: [
      {
        path: "new-survey",
        component: AdminNewSurweyComponent,
      },
      {
        path: "edit-survey/:id",
        component: AdminNewSurweyComponent,
      },
      {
        path: "company-info",
        component: AdminCompanyInfoComponent,
      },
      {
        path: "questions",
        component: AdminQuestionsComponent,
      },
      {
        path: "reports",
        component: AdminReportsComponent,
      },
      {
        path: "statistics",
        component: AdminStatisticsComponent,
      },
      {
        path: "companies",
        component: RootCompaniesComponent,
      },
      {
        path: "sectors",
        component: RootSectorsComponent,
      },
      {
        path: "customers",
        component: RootCustomersComponent,
      },
      {
        path: "forms",
        component: RootFormsComponent,
      },
      {
        path: "form-coupons",
        component: RootCompanyFormCouponsComponent,
      },
      {
        path: "verify-discount-code",
        component: AdminVerifyDiscountCodeComponent,
      },
      {
        path: "survey-discount-codes/:id",
        component: AdminSurveyDiscountCodesComponent,
      },
      {
        path: "blog",
        component: RootBlogsComponent,
      },
      {
        path: "survey-sms-text/:id",
        component: AdminSurveySmsTextComponent,
      },
      {
        path: "home-survey-sorting",
        component: RootHomeSurveySortingComponent,
      },
      {
        path: "user-reports",
        component: RootUserReportsComponent,
      },
      {
        path: "admin-user-infos",
        component: AdminUserInfosComponent,
      },
      {
        path: "search-logs",
        component: RootSearchLogsComponent,
      },
      {
        path: "pazanova",
        component: AdminPazanovaComponent,
      },
    ],
  },
  {
    path: "email-verification-success",
    component: EmailVerificationSuccessComponent,
  },
  {
    path: "email-verification-success/:id",
    component: EmailVerificationSuccessComponent,
  },
  { path: "my-earnings", component: UserEarningsComponent },
  { path: "bizden-haberler", component: BizdenHaberlerComponent },
  { path: "under-construction", component: UnderConstructionComponent },
  { path: "blog", component: BlogComponent },
  { path: "blog/:slugUrl", component: BlogDetailComponent },
  { path: "marka/:companyUrl/:id", component: CampaignDetailComponent },
  { path: ":companyUrl/:id", component: SurveyComponent },
  { path: ":companyUrl/:id/tesekkurler", component: SurveyThanksComponent },
  { path: "*", component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
