<div class="holder">
  <div class="logo-wrapper">
    <a class="logo-holder" routerLink="/">
      <img src="../../../assets/images/logo.svg" alt="" />
    </a>
  </div>
  <div class="card">
    <h1>Hoşgeldiniz</h1>
    <div *ngIf="state == 'login'">
      <h2>Giriş yapmak için lütfen bilgilerinizi girin</h2>
      <ng-template [ngIf]="!verifyHolder">
        <div style="margin-top: 2rem" class="social-buttons-holder">
          <button
            class="phone-button"
            [ngClass]="{
              'phone-button-active': loginState == 'phone'
            }"
            (click)="loginState = 'phone'"
          >
            Müşteri Girişi
          </button>
          <button
            class="phone-button"
            [ngClass]="{
              'phone-button-active': loginState == 'email'
            }"
            (click)="loginState = 'email'"
          >
            Kurumsal Giriş
          </button>
        </div>
        <div *ngIf="loginState == 'email'">
          <div class="form-control-holder">
            <label for="email">e-Posta</label>
            <input
              type="email"
              id="email"
              [(ngModel)]="email"
              #ctrl="ngModel"
            />
          </div>
          <div class="form-control-holder">
            <label for="password">Şifre</label>
            <app-password-input
              [(model)]="password"
              id="password"
              name="password"
            ></app-password-input>
            <div class="bottoms">
              <div class="remember-me">
                <input type="checkbox" id="forgatten" />
                <label for="forgatten">Beni Hatırla</label>
              </div>
              <div class="forgot-password">
                <a [routerLink]="['/forgot-password/' + loginState]"
                  ><label>Şifremi Unuttum</label></a
                >
              </div>
            </div>
          </div>
          <div class="login-button-holder">
            <app-button
              type="simple"
              text="Giriş Yap"
              (click)="login()"
            ></app-button>
          </div>
        </div>
        <div *ngIf="loginState == 'phone'">
          <div class="form-control-holder">
            <label for="phone">Telefon</label>
            <input
              type="text"
              id="phone"
              [(ngModel)]="phone"
              #ctrl="ngModel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              maxlength="10"
              title="Lütfen başında 0 olmadan giriniz"
              placeholder="5321231212"
            />
          </div>
          <div class="form-control-holder">
            <label for="password">Şifre</label>
            <app-password-input
              id="password"
              [(model)]="password"
              name="password"
            ></app-password-input>
            <div class="bottoms">
              <div class="remember-me">
                <input type="checkbox" id="forgatten" />
                <label for="forgatten">Beni Hatırla</label>
              </div>
              <div class="forgot-password">
                <a (click)="showForgotPassword = true"
                  ><label>Şifremi Unuttum</label></a
                >
              </div>
            </div>
          </div>
          <div class="login-button-holder">
            <app-button
              type="simple"
              text="Giriş Yap"
              (click)="login()"
            ></app-button>
          </div>
        </div>
        <div class="register-button-holder">
          <span
            >Hesabınız yok mu?
            <app-button
              type="link link-orange"
              text="Hemen Kaydolun!"
              (click)="changeState('register')"
            ></app-button
          ></span>
        </div>
      </ng-template>
    </div>
    <div *ngIf="state == 'register'">
      <h2>Kayıt olmak için lütfen bilgilerinizi girin</h2>
      <!-- <div
        style="margin-top: 2rem; margin-bottom: 2rem"
        class="social-buttons-holder"
      >
        <button
          *ngIf="registerState == 'email'"
          class="phone-button"
          (click)="registerState = 'phone'"
        >
          Telefon ile Kayıt Ol
        </button>
        <button
          *ngIf="registerState == 'phone'"
          class="phone-button"
          (click)="registerState = 'email'"
        >
          e-Mail ile Kayıt Ol
        </button>
      </div> -->

      <form *ngIf="!registerAuthState" (ngSubmit)="register()">
        <div class="form-control-holder" *ngIf="registerState == 'email'">
          <label for="email">e-Mail Adresi</label>
          <input
            type="email"
            id="email"
            name="email"
            [(ngModel)]="email"
            #ctrl="ngModel"
          />
        </div>
        <div class="form-control-holder" *ngIf="registerState == 'phone'">
          <label for="phone">Telefon No</label>
          <input
            type="phone"
            id="phone"
            name="phone"
            [(ngModel)]="phone"
            #ctrl="ngModel"
            maxlength="10"
            title="Lütfen başında 0 olmadan giriniz"
            placeholder="5321231212"
          />
        </div>

        <div class="bottoms" style="margin-bottom: 10px">
          <div class="remember-me kullanimkosullari">
            <input
              type="checkbox"
              id="ticariIletiAgreement"
              [checked]="ticariIletiAgreement"
              (change)="ticariIletiAgreement = !ticariIletiAgreement"
            />
            <label for="ticariIletiAgreement" style="white-space: break-spaces">
              <a href="/ticari-ileti" target="_blank"
                >Elektronik Ticari İleti Bilgilendirme Metni</a
              >
              kapsamında Bilgesin tanıtım içeriklerinden haberdar olmak
              istiyorum.
            </label>
          </div>
        </div>
        <div class="bottoms" style="margin-bottom: 10px">
          <div class="remember-me kullanimkosullari">
            <input
              type="checkbox"
              id="openConsentAgreement"
              [checked]="openConsentAgreement"
              (change)="openConsentAgreement = !openConsentAgreement"
            />
            <label for="openConsentAgreement" style="white-space: break-spaces">
              <a href="/pazarlama-acik-riza" target="_blank"
                >Bilgesin Pazarlama Faaliyetleri Açık Rıza Metni</a
              >
              kapsamında anket bilgilerimin pazarlama ve tanıtım amacıyla
              işlenmesine onay veriyorum.
            </label>
          </div>
        </div>
        <div class="login-button-holder" style="justify-content: center">
          <app-button type="simple" text="Kayıt Ol"></app-button>
        </div>
        <div class="register-agreement">
          Kayıt Ol’a basarak Bilgesin
          <a href="/kullanim-kosullari" target="_blank">Kullanım Koşullarını</a>
          onayladığınız ve KVKK
          <a href="/kisisel-verilerin-korunmasi" target="_blank"
            >Gizlilik ve Çerez Politikasını</a
          >
          okuyup, anladığınızı kabul edersiniz.
        </div>
      </form>
      <div class="register-button-holder">
        <span
          >Hesabınız var mı?
          <app-button
            type="link link-orange"
            text="Hemen Giriş Yapın!"
            (click)="changeState('login')"
          ></app-button
        ></span>
      </div>
    </div>
  </div>
</div>

<app-reset-password
  [phone]="phone"
  [show]="showForgotPassword"
  (hide)="hideResetPassword($event)"
></app-reset-password>
