import { HttpClient } from "@angular/common/http";
import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { finalize, take } from "rxjs/operators";
import {
  fixStringLength,
  sportiveCompanyId,
  verifyPhoneNumber,
  pickhobiCompanyId,
  estmarineCompanyId,
  ascsTanitimCompanyId,
  tatilimSensinCompanyId,
  aktaslarLezzetCompanyId,
  pickhobiDiscountCode,
  estmarineDiscountCode,
  boostOxygenCompanyId,
  boostOxygenDiscountCode,
  leoparCompanyId,
  leoparDiscountCode,
  mugoCompanyId,
  muhikuCompanyId,
  muhikuDiscountCode,
  muhikuUtmLink,
  belTurCompanyId,
  scrollToTop,
  yasomiCompanyId,
  landingPageConfig,
} from "src/app/helpers/helpers";
import { MetaService } from "src/app/services/meta.service";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-survey",
  templateUrl: "./survey.component.html",
  styleUrls: ["./survey.component.scss"],
})
export class SurveyComponent implements OnInit, OnDestroy {
  surveyUrl = "";
  surveyId = "";
  survey;
  percentage = 0;
  state = "";
  discountCode = "";
  image = null;
  popupState = true;
  time = 0;
  isUserLoggedIn = false;
  userHasPhoneNumber = false;
  surveyFinished = false;
  isUserActive = false;

  phone = "";
  phoneCorrect = false;
  isUserExistsWithPhone = false;
  isUserExistsWithPhoneLoading = false;
  isUserExistsWithPhoneInterval;
  phoneSmsSent = false;
  phonePass = "";
  openConsentAgreement = false;
  ticariIletiAgreement = false;

  results = [];
  breadcrumbData = [];

  get isRegisterButton() {
    return (
      !this.isUserLoggedIn && !this.isUserExistsWithPhone && this.phoneCorrect
    );
  }

  verifySentDate = new Date();
  verificationSmsSecond = 0;
  verificationInterval;
  verificationSending = false;

  showResetPassword = false;

  finishSurveyWorking = false;

  productLinksModalShowing = false;

  landingPageKey = "";

  get resendCodeText() {
    if (this.verificationSmsSecond >= 0) {
      return `${this.verificationSmsSecond}sn. - Şifreyi yeniden gönder`;
    }
    return "Şifreyi yeniden gönder";
  }

  get discountEarningDesc() {
    let discountDesc = "";
    if (!this.survey) {
      return discountDesc;
    }

    if (this.survey.discountType === "Indirim") {
      discountDesc = `${this.survey.discountAmount} ${this.survey.discountAmountType} İndirim`;
    } else {
      discountDesc = `${this.survey.discountDescription?.trim() || ""}`;
    }
    return discountDesc;
  }

  get discountCodeTitleText() {
    if (this.survey?.companyId === estmarineCompanyId) {
      return "Tebrikler, anketiniz başarıyla tamamlandı ve kampanya açıklamamızda belirttiğimiz seçili ürünlerde 15 %’e varan indirim kazandınız. 🙂";
    }

    return `Tebrikler, anketiniz başarıyla tamamlandı ve ${
      this.survey?.company?.name || "..."
    } markasından ${this.discountEarningDesc} kazandınız. 🙂`;
  }

  get discountCodeDescText() {
    let discountTypeText = "";
    switch (this.survey?.discountType) {
      case "Hediye":
        discountTypeText = "Hediye";
        break;
      case "Indirim":
        discountTypeText = "İndirim";
        break;
      default:
        discountTypeText = this.survey?.discountType.toString();
    }

    if (this.survey?.companyId === ascsTanitimCompanyId) {
      return "İşte kodunuz: ";
    }
    if (this.survey?.companyId === sportiveCompanyId) {
      return `İndirim kodunuzu <a href="https://www.sportive.com.tr" target="_blank">www.sportive.com.tr</a> ve Sportive Mobil Uygulamasında kullanabilirsiniz. İndirim kodunuzu sepeti onaylamadan "İndirim Kodunuz Var Mı?" yazan alana girebilirsiniz. <br/> İşte ${discountTypeText} kodunuz: `;
    }
    if (this.survey?.companyId === pickhobiCompanyId) {
      return `İndirim kodunuzu <a href="https://www.pickhobi.com" target="_blank">www.pickhobi.com</a> web sitesinde kullanabilirsiniz. İndirim kodunuzu sepeti onaylamadan "Kupon Kullan" yazan alana girebilirsiniz. <br/> İşte ${discountTypeText} kodunuz: `;
    }
    if (this.survey?.companyId === estmarineCompanyId) {
      return `İndirim kodunuzu tüm Eastmarine mağazalarında kullanabilirsiniz. <br/> İşte ${discountTypeText} kodunuz: `;
    }
    if (this.survey?.companyId === tatilimSensinCompanyId) {
      return `Hediye puanınızı <a href="https://www.tatilimsensin.com" target="_blank">www.tatilimsensin.com</a> ve Tatilimsensin Mobil uygulamasında kullanabilirsiniz. Hediye puanını kullanmak için bizimle paylaştığınız telefon numarası ile Tatilimsensin’e üye olmanız yeterlidir. Hediye puanı üye olduktan sonra 48 saat içinde hesabınıza yüklenecektir.`;
    }
    if (this.survey?.companyId === aktaslarLezzetCompanyId) {
      return `Hediyenizi kullanmak için Nelipide Gurme yetkilisine size özel indirim kodunu söylemeniz yeterlidir. İşte ${discountTypeText} kodunuz: <b>${this.discountCode}</b> <br /> Adres: Göztepe Mah. Bağdat Cad. No:199/B Kadıköy/İSTANBUL Tel: 0 216 356 52 52`;
    }
    if (this.survey?.companyId === muhikuCompanyId) {
      return `İndirim kodunuzu Muhiku web sitesinde (<a href="${muhikuUtmLink}" target="_blank">www.muhiku.com</a>) kullanabilirsiniz. Kampanyadan yararlanmak için indirim kodunuzu, Muhiku.com’da ödeme sayfasında yer alan ‘’Kupon Kodu’’ alanına yazmanız yeterlidir. İşte İndirim kodunuz: `;
    }
    if (this.survey?.companyId === mugoCompanyId) {
      return `İndirim kodunuzu Mugo web sitesinde (<a href="https://mugo.com.tr" target="_blank">www.mugo.com.tr</a>) kullanabilirsiniz. Kampanyadan yararlanmak için indirim kodunuzu, Mugo.com.tr’de ödeme sayfasında yer alan ‘’İndirim kodu alanına yazmanız yeterlidir. İşte İndirim kodunuz: “<b>${this.discountCode}</b>”`;
    }

    return `İşte ${discountTypeText} kodunuz: `;
  }

  get showDiscountCode() {
    return (
      this.survey?.companyId !== tatilimSensinCompanyId &&
      this.survey?.companyId !== aktaslarLezzetCompanyId &&
      this.survey?.companyId !== mugoCompanyId
    );
  }

  get showDiscountQr() {
    return false;
    return (
      this.image &&
      this.survey?.companyId !== sportiveCompanyId &&
      this.survey?.companyId !== belTurCompanyId
    );
  }

  get customGiftMessage() {
    if (this.survey?.customGiftText) {
      let discountDesc = "";
      if (this.survey.discountType === "Indirim") {
        discountDesc = `${this.survey.discountAmount} ${this.survey.discountAmountType} İndirim`;
      } else {
        discountDesc = `${this.survey.discountDescription?.trim() || ""}`;
      }

      return this.survey.customGiftText
        .replace(/\[discountCode]/g, this.discountCode)
        .replace(/\[discountDescription]/g, discountDesc);
    }
    return `${this.discountCodeDescText}${
      this.showDiscountCode ? ` ${this.discountCode}` : ""
    }`;
  }

  get showProductsLinks() {
    return this.survey?.companyId === yasomiCompanyId;
  }

  get showLpTitle() {
    return !!this.landingPageKey;
  }

  get showLpLogo() {
    return !!this.landingPageKey;
  }

  get showLpTopLeft() {
    return !!this.landingPageKey;
  }

  get showLpTopRight() {
    return !!this.landingPageKey;
  }

  get showLpBottomLeft() {
    return !!this.landingPageKey;
  }

  get showLpBottomRight() {
    return !!this.landingPageKey;
  }

  get lpTopRightSrc() {
    if (this.landingPageKey === "sportive") {
      return "/assets/lp/sportive/top-right-ball.png";
    }
    return `/assets/lp/${this.landingPageKey}/top-right.png`;
  }

  get showLpBorder() {
    return this.landingPageKey === "boost";
  }

  constructor(
    private _sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private http: HttpClient,
    private userService: UserService,
    private metaService: MetaService,
    private router: Router
  ) {
    this.surveyUrl = this.route.snapshot.paramMap.get("id");
    for (const lp of landingPageConfig) {
      if (this.surveyUrl === lp.lpUrl || this.surveyUrl === lp.slugUrl) {
        this.surveyUrl = lp.slugUrl.toString();
        this.landingPageKey = lp.key.toString();
        localStorage.setItem(`lp_${this.landingPageKey}`, "true");
        document.body.classList.add(this.landingPageKey);
        this.trackPageView();
        break;
      }
    }

    this.timeCounter();
  }

  ngOnInit(): void {
    scrollToTop();
    this.getSurvey();
    this.userService.user.subscribe((res) => {
      this.isUserLoggedIn = !!res;
      this.userHasPhoneNumber = !!res?.phone;
      this.isUserActive = !!res?.isActive;
    });
  }

  ngOnDestroy(): void {
    if (this.verificationInterval) {
      clearInterval(this.verificationInterval);
    }

    if (this.landingPageKey) {
      document.body.classList.remove(this.landingPageKey);
    }
  }

  trackPageView() {
    if (this.landingPageKey) {
      this.http
        .post("/pazanova/track-page-view", {
          landingPageKey: this.landingPageKey,
        })
        .pipe(take(1))
        .subscribe(() => {});
    }
  }

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "Enter") {
      this.finishSurvey();
    }
  }

  timeCounter() {
    setInterval(() => {
      this.time++;
    }, 1000);
  }

  isAvailableToShow(
    type,
    relatedQuestionIndex,
    connectionOptions,
    maximumValue
  ) {
    let output = false;
    if (type == "multiple") {
      if (
        typeof this.results[relatedQuestionIndex] == "string" ||
        typeof this.results[relatedQuestionIndex] == "number"
      ) {
        output =
          connectionOptions.find(
            (x) => this.results[relatedQuestionIndex] == x
          ) > -1;
      } else {
        output =
          connectionOptions.find(
            (x) => this.results[relatedQuestionIndex].indexOf(x) > -1
          ) > -1;
      }
    } else {
      if (typeof maximumValue === "undefined") {
        if (this.results[relatedQuestionIndex] >= connectionOptions) {
          output = true;
        }
      } else {
        if (
          this.results[relatedQuestionIndex] >= connectionOptions &&
          this.results[relatedQuestionIndex] <= maximumValue
        ) {
          output = true;
        }
      }
    }

    if (output) {
      const parent = this.survey.questions[relatedQuestionIndex];
      if (parent.isConnected == "true") {
        const isParentShowing = this.isAvailableToShow(
          "multiple",
          parent.connectedQuestionIndex,
          parent.connectionOptions,
          undefined
        );
        if (!isParentShowing) {
          output = false;
        }
      }
    }
    return output;
  }

  getSurvey() {
    this.http
      .post("/getSurvey", {
        id: this.surveyUrl,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.survey = res;
        this.surveyId = res._id.toString();
        this.breadcrumbData = [{ name: "Anasayfa", href: "/" }];
        if (res.company?.name) {
          this.breadcrumbData.push({
            name: fixStringLength(res.company.name, 50),
            href: `/marka/${res.company.slugUrl || res.company._id}/${
              this.surveyUrl
            }`,
          });
        }
        this.breadcrumbData.push({
          name: fixStringLength(res.name, 50),
          active: true,
        });
        res.questions.forEach((element) => {
          if (element.valueCount > 1) {
            this.results.push([]);
          } else {
            this.results.push("");
          }
        });

        this.setMeta();
      });
  }

  setMeta() {
    let disc = "";
    if (this.survey.discountType !== "Hediye") {
      disc = `${this.survey.discountAmount} ${this.survey.discountAmountType} indirim`;
    } else if (this.survey.discountType === "Hediye") {
      disc = this.survey.discountDescription?.trim();
    }

    const companyName = this.survey.company?.name || "";
    this.metaService.set({
      title: `${companyName} şirketinin anketini doldur ${disc} kazan`,
      description: `${companyName} kampanya fırsatını kaçırmayın. ${companyName} anketine katıl ${disc} Kazan.`,
    });
  }

  finishSurvey() {
    if (this.survey.timeLimit > this.time) {
      this.popupState = false;
      this.state = "not_valid";
      return false;
    }

    if (!this.validateSurvey()) {
      this.popupState = false;
      this.state = "not_valid";
      return false;
    }

    if (!this.isUserLoggedIn || !this.userHasPhoneNumber) {
      if (!this.phoneCorrect) {
        verifyPhoneNumber(this.phone, true);
        return false;
      }
      if (!this.isRegisterButton && !this.phonePass) {
        alert("Lütfen Şifre alanını doldurunuz!");
        return false;
      }
      if (this.isRegisterButton && !this.phonePass) {
        this.state = "verification";
        this.sendVerifyCodeToPhone();
        return false;
      }
    }

    this.surveyFinished = true;
    this.finishSurveyWorking = true;

    this.http
      .post("/saveSurveyResult", {
        landingPageKey: this.landingPageKey,
        finishTimeInSeconds: this.time,
        surveyId: this.surveyId,
        answers: this.results,
        newUser:
          this.isUserLoggedIn && this.userHasPhoneNumber
            ? null
            : {
                phone: this.phone,
                password: this.phonePass,
                openConsentAgreement: this.openConsentAgreement,
                ticariIletiAgreement: this.ticariIletiAgreement,
              },
      })
      .pipe(
        take(1),
        finalize(() => {
          this.finishSurveyWorking = false;
        })
      )
      .subscribe((saveRes: any) => {
        if (typeof saveRes.data === "string") {
          alert(saveRes.data);
        } else {
          this.trackMeta();

          if (this.survey.companyId === pickhobiCompanyId) {
            this.discountCode = pickhobiDiscountCode;
          } else if (this.survey.companyId === estmarineCompanyId) {
            this.discountCode = estmarineDiscountCode;
          } else if (this.survey.companyId === boostOxygenCompanyId) {
            this.discountCode = boostOxygenDiscountCode;
          } else if (this.survey.companyId === leoparCompanyId) {
            this.discountCode = leoparDiscountCode;
          } else if (this.survey.companyId === muhikuCompanyId) {
            this.discountCode = muhikuDiscountCode;
          } else {
            this.discountCode = saveRes.data.discountCode;
          }
          if (saveRes.data.user && saveRes.data.token) {
            this.userService.saveLoginResponse(saveRes.data);
          }
          if (this.landingPageKey) {
            this.navigateToThanksPage();
          }
          this.getQr();
        }
      });
  }

  validateSurvey() {
    let result = true;
    this.survey.questions
      .filter((m) => !m.hide)
      .forEach((question, index) => {
        if (question.isRelated == "true") {
          let relatedQuestionAnswer = Number(
            this.results[question.relatedQuestionIndex]
          );
          let currentQuestionAnswer = Number(this.results[index]);
          if (currentQuestionAnswer > relatedQuestionAnswer) {
            if (
              currentQuestionAnswer - relatedQuestionAnswer >=
              Number(question.relatedQuestionError)
            ) {
              result = false;
            }
          } else {
            if (
              relatedQuestionAnswer - currentQuestionAnswer >=
              Number(question.relatedQuestionError)
            ) {
              result = false;
            }
          }
        }
      });
    return result;
  }

  getBackground() {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${this.image})`);
  }

  returnHome() {
    this.state = "";
    this.router.navigateByUrl("/");
  }

  handleCheckboxSelect(index, optionValue, valueCount) {
    if (!this.results[index]) {
      this.results[index] = [];
      this.results[index].push(optionValue);
    } else {
      const optionIndex = this.results[index].indexOf(optionValue);
      if (optionIndex > -1) {
        this.results[index].splice(optionIndex, 1);
      } else {
        if (this.results[index].length >= parseInt(valueCount) + 1) {
          this.results[index].shift();
          this.results[index].push(optionValue);
        } else {
          this.results[index].push(optionValue);
        }
      }
    }
  }

  reSendForm(user): any {
    if (user) {
      this.isUserLoggedIn = true;
      if (user.phone) {
        this.userHasPhoneNumber = true;
      }
      if (user.isActive) {
        this.isUserActive = true;
      }
    }
    this.finishSurvey();
  }

  getQr() {
    let qrUrl = `https://${environment.hostName()}/survey/${this.surveyUrl}`;
    if (this.survey?.companyId === muhikuCompanyId) {
      qrUrl = muhikuUtmLink;
    }
    this.http
      .post("/getQR", {
        id: qrUrl,
      })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.image = res.qr;
        if (!this.landingPageKey) {
          this.state = "finished";
        }
      });
  }

  onPhoneChange() {
    this.phoneCorrect = verifyPhoneNumber(this.phone, false);
    if (!this.phoneCorrect) {
      return;
    }

    this.isUserExistsWithPhoneLoading = true;
    this.http
      .get(`/is-phone-exists?phone=${this.phone}`)
      .pipe(
        take(1),
        finalize(() => (this.isUserExistsWithPhoneLoading = false))
      )
      .subscribe((res: any) => {
        this.isUserExistsWithPhone = !!res.userExists;
        if (this.isUserExistsWithPhone && this.isUserLoggedIn) {
          alert(
            "Telefon numarası başka bir hesaba kayıtlı, lütfen telefon numarasıyla giriş yapınız."
          );
        }
      });
  }

  trackMeta() {
    try {
      if (this.landingPageKey) {
        const ph = this.phone || this.userService.getUser()?.phone || "";
        (window as any).fbq("trackCustom", "ArastirmaTamamlandi", {
          phone: ph,
          key: this.landingPageKey,
        });
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  sendVerifyCodeToPhone(again = false) {
    if (this.phoneSmsSent && !again) {
      return;
    }

    if (!this.isUserExistsWithPhone) {
      this.verificationInterval = setInterval(() => {
        const now = new Date();
        this.verificationSmsSecond =
          60 -
          parseInt(
            ((now.getTime() - this.verifySentDate.getTime()) / 1000).toString()
          );
      }, 1000);

      this.http
        .post("/send-verify-code-to-phone", {
          phone: this.phone,
        })
        .pipe(
          take(1),
          finalize(() => {
            setTimeout(() => (this.verificationSending = false), 1000);
          })
        )
        .subscribe((res: any) => {
          this.phoneSmsSent = !!(res.alreadySent || res.sent);
          if (this.phoneSmsSent) {
            this.verifySentDate = new Date();
          }
          if (!res.alreadySent && !res.sent) {
            alert(res.message);
          }
        });
    }
  }

  hideResetPassword(result) {
    this.showResetPassword = false;

    if (!result || !result.resetStatus) {
      return;
    }

    this.phonePass = result.password;
    this.phone = result.phone;
    this.phoneCorrect = verifyPhoneNumber(this.phone, false);
    this.finishSurvey();
  }

  closeSuccessDialog() {
    this.state = "";
    this.returnHome();
  }

  labelHtml(option) {
    if (this.landingPageKey) {
      return option.text;
    }
    return `${option.value} ) ${option.text}`;
  }

  navigateToThanksPage() {
    const searchRemovedUrl = this.router.url.replace(
      window.location.search,
      ""
    );
    const url = searchRemovedUrl.endsWith("/")
      ? `${searchRemovedUrl}tesekkurler`
      : `${searchRemovedUrl}/tesekkurler`;

    localStorage.setItem(
      url,
      JSON.stringify({
        landingPageKey: this.landingPageKey,
        discountCode: this.discountCode,
      })
    );
    window.location.href = url;
    //this.router.navigateByUrl(url);
  }
}
