<div
  class="holder {{ landingPageKey }} {{
    landingPageKey ? 'landing-page-holder' : ''
  }}"
>
  <div *ngIf="state == 'finished'" class="successMessage">
    <div *ngIf="popupState">
      <img
        src="/assets/images/popup-close.svg"
        class="popup-close"
        (click)="closeSuccessDialog()"
      />
      <ng-template [ngIf]="survey.isCustomGiftMessageActive">
        <div [innerHTML]="customGiftMessage"></div>
        <div
          *ngIf="showDiscountQr"
          [style.background-image]="getBackground()"
          class="image-preview"
        ></div>
      </ng-template>
      <ng-template [ngIf]="!survey.isCustomGiftMessageActive">
        {{ discountCodeTitleText }}
        <div>
          <span [innerHTML]="discountCodeDescText"></span>
          <b *ngIf="showDiscountCode">{{ discountCode }}</b> <br />
          <div
            *ngIf="showDiscountQr"
            [style.background-image]="getBackground()"
            class="image-preview"
          ></div>
        </div>
      </ng-template>

      <div class="close-dialog">
        <app-button
          [type]="landingPageKey || 'simple'"
          text="Kapat"
          (click)="closeSuccessDialog()"
        >
        </app-button>
      </div>
    </div>
  </div>
  <div class="successMessage" *ngIf="state === 'not_valid'">
    <div>
      <img
        src="/assets/images/popup-close.svg"
        class="popup-close"
        (click)="returnHome()"
      />
      Üzgünüz anket güvenlik testlerimizden geçemedi.
      <div class="close-dialog">
        <app-button
          [type]="landingPageKey || 'simple'"
          text="Kapat"
          (click)="returnHome()"
        >
        </app-button>
      </div>
    </div>
  </div>
  <div class="successMessage" *ngIf="state == 'verification'">
    <div>
      <img
        src="/assets/images/popup-close.svg"
        class="popup-close"
        (click)="state = ''"
      />
      <h1>Hoşgeldiniz</h1>
      <h2>Lütfen telefonunuza gelen şifreyi giriniz.</h2>
    </div>
    <div class="form-control-holder">
      <label for="phonePass">Şifre</label>
      <input
        type="text"
        id="phonePass"
        [(ngModel)]="phonePass"
        #ctrl="ngModel"
      />
    </div>
    <div class="login-button-holder">
      <app-button
        [type]="landingPageKey || 'simple'"
        text="Onayla"
        (click)="finishSurvey()"
      ></app-button>
    </div>
    <app-button
      type="link {{ landingPageKey || 'link-orange' }}"
      [text]="resendCodeText"
      (click)="sendVerifyCodeToPhone(true)"
      *ngIf="!verificationSending"
    ></app-button>
  </div>
  <div
    class="backdrop"
    *ngIf="state == 'finished' || state == 'verification'"
  ></div>

  <div class="container" *ngIf="!landingPageKey">
    <app-breadcrumb [data]="breadcrumbData"></app-breadcrumb>
  </div>

  <div class="lp-title" *ngIf="showLpTitle">
    <img src="/assets/lp/{{ landingPageKey }}/title.svg" />
    <img
      class="mobile"
      src="/assets/lp/{{ landingPageKey }}/title-mobile.svg"
    />
  </div>

  <div
    class="container gray-card"
    *ngIf="survey"
    [ngClass]="{
      'inputs-disabled': survey.isCampaignExpired
    }"
  >
    <div class="logo-holder" *ngIf="!landingPageKey">
      <img
        src="../../../assets/images/logo-dark.svg"
        alt=""
        *ngIf="!survey.company?.logo"
      />
      <img [src]="survey.company?.logo" alt="" *ngIf="survey.company?.logo" />
    </div>

    <div class="lp-border" *ngIf="showLpBorder"></div>

    <div class="logo-holder" *ngIf="showLpLogo">
      <img src="/assets/lp/{{ landingPageKey }}/logo.png" />
    </div>

    <div class="lp-top-left" *ngIf="showLpTopLeft">
      <img src="/assets/lp/{{ landingPageKey }}/top-left.png" />
    </div>

    <div class="lp-top-right" *ngIf="showLpTopRight">
      <img [src]="lpTopRightSrc" />
    </div>

    <div class="lp-bottom-left" *ngIf="showLpBottomLeft">
      <img src="/assets/lp/{{ landingPageKey }}/bottom-left.png" />
    </div>

    <div class="lp-bottom-right" *ngIf="showLpBottomRight">
      <img src="/assets/lp/{{ landingPageKey }}/bottom-right.png" />
    </div>

    <div class="title-holder">
      <h1 [innerHTML]="survey.name"></h1>
      <ng-template [ngIf]="survey.isCampaignExpired">
        <span>
          Bu kampanya
          {{ survey.campaignExpireDateTime | date : "dd.MM.yyyy" }} tarihinde
          bitmiştir. Yeni kampanyalar için bizi takip etmeye devam edin
        </span>
      </ng-template>
      <span
        *ngIf="survey.description && !survey.isCampaignExpired"
        [innerHTML]="survey.description"
      ></span>
      <span
        *ngIf="
          survey.description && !survey.isCampaignExpired && showProductsLinks
        "
        (click)="productLinksModalShowing = true"
        class="product-links-modal-button"
      >
        Seçili ürünleri görmek için tıklayınız.
      </span>
    </div>
    <div class="questions-holder" *ngIf="results.length">
      <ng-container *ngFor="let item of survey.questions; let i = index">
        <ng-template [ngIf]="!item.hide">
          <ng-container *ngIf="item.isConnected !== 'true'">
            <div class="question">
              <div>
                <div class="question-text">
                  <span [innerHTML]="item.question.text"></span>
                  <ng-container
                    *ngIf="
                      item.sort == 'multiple' &&
                      item.question.valueCount > 0 &&
                      item.kind == 'close'
                    "
                  >
                    <br />
                    <small>
                      *En fazla {{ item.question.valueCount - 0 + 1 }} şık
                      seçebilirsiniz
                    </small>
                  </ng-container>
                </div>
                <div class="question-options" *ngIf="item.kind == 'open'">
                  <input
                    class="open-question"
                    name="question1"
                    type="text"
                    [(ngModel)]="results[i]"
                  />
                </div>
                <div
                  class="question-options"
                  *ngIf="item.kind == 'close'"
                  [ngClass]="item.sort"
                >
                  <div
                    *ngFor="let option of item.question.options; let j = index"
                  >
                    <ng-container *ngIf="item.sort == 'multiple'">
                      <ng-container *ngIf="item.question.valueCount > 0">
                        <input
                          name="question{{ i }}"
                          type="checkbox"
                          [checked]="results[i].indexOf(option.value) > -1"
                          [value]="option.value"
                          (click)="
                            handleCheckboxSelect(
                              i,
                              option.value,
                              item.question.valueCount
                            )
                          "
                          id="question{{ i }}option{{ j }}"
                        />
                      </ng-container>
                      <ng-container
                        *ngIf="
                          item.question.valueCount === 0 ||
                          item.question.valueCount === '0'
                        "
                      >
                        <input
                          name="question{{ i }}"
                          type="radio"
                          [value]="option.value"
                          (click)="results[i] = option.value"
                          id="question{{ i }}option{{ j }}"
                        />
                      </ng-container>
                      <label
                        class="multiple-label"
                        *ngIf="item.sort == 'multiple'"
                        for="question{{ i }}option{{ j }}"
                        [innerHTML]="labelHtml(option)"
                      ></label>
                    </ng-container>
                    <ng-container *ngIf="item.sort == 'range'">
                      <input
                        name="question{{ i }}"
                        type="radio"
                        [value]="option.value"
                        (click)="results[i] = option.value"
                        id="question{{ i }}option{{ j }}"
                      />
                      <label for="question{{ i }}option{{ j }}">{{
                        option.value ? option.value : option.text
                      }}</label>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="item.isConnected === 'true'">
            <ng-container
              *ngIf="item.connectionOptions && item.connectionOptions.length"
            >
              <ng-container
                *ngIf="
                  isAvailableToShow(
                    'multiple',
                    item.connectedQuestionIndex,
                    item.connectionOptions,
                    undefined
                  )
                "
              >
                <div class="question">
                  <div>
                    <div class="question-text">
                      <span [innerHTML]="item.question.text"></span>
                      <ng-container
                        *ngIf="
                          item.sort == 'multiple' &&
                          item.question.valueCount > 0 &&
                          item.kind == 'close'
                        "
                      >
                        <br />
                        <small>
                          *En fazla {{ item.question.valueCount - 0 + 1 }} şık
                          seçebilirsiniz
                        </small>
                      </ng-container>
                    </div>
                    <div class="question-options" *ngIf="item.kind == 'open'">
                      <input
                        class="open-question"
                        name="question1"
                        type="text"
                        [(ngModel)]="results[i]"
                      />
                    </div>
                    <div
                      class="question-options"
                      *ngIf="item.kind == 'close'"
                      [ngClass]="item.sort"
                    >
                      <div
                        *ngFor="
                          let option of item.question.options;
                          let j = index
                        "
                      >
                        <ng-container *ngIf="item.sort == 'multiple'">
                          <input
                            name="question{{ i }}"
                            type="checkbox"
                            [checked]="results[i].indexOf(option.value) > -1"
                            [value]="option.value"
                            (click)="
                              handleCheckboxSelect(
                                i,
                                option.value,
                                item.question.valueCount
                              )
                            "
                            id="question{{ i }}option{{ j }}"
                          />
                          <label
                            class="multiple-label"
                            *ngIf="item.sort == 'multiple'"
                            for="question{{ i }}option{{ j }}"
                            [innerHTML]="labelHtml(option)"
                          ></label>
                        </ng-container>
                        <ng-container *ngIf="item.sort == 'range'">
                          <input
                            name="question{{ i }}"
                            type="radio"
                            [value]="option.value"
                            (click)="results[i] = option.value"
                            id="question{{ i }}option{{ j }}"
                          />
                          <label for="question{{ i }}option{{ j }}">{{
                            option.value ? option.value : option.text
                          }}</label>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container
              *ngIf="
                !(item.connectionOptions && item.connectionOptions.length) &&
                item.minimumValue
              "
            >
              <ng-container
                *ngIf="
                  isAvailableToShow(
                    'ranged',
                    item.connectedQuestionIndex,
                    item.minimumValue,
                    item.maximumValue
                  )
                "
              >
                <div class="question">
                  <div>
                    <div class="question-text">
                      <span [innerHTML]="item.question.text"></span>
                    </div>
                    <div class="question-options" *ngIf="item.kind == 'open'">
                      <input
                        class="open-question"
                        name="question1"
                        type="text"
                        [(ngModel)]="results[i]"
                      />
                    </div>
                    <div
                      class="question-options"
                      *ngIf="item.kind == 'close'"
                      [ngClass]="item.sort"
                    >
                      <div
                        *ngFor="
                          let option of item.question.options;
                          let j = index
                        "
                      >
                        <ng-container *ngIf="item.sort == 'multiple'">
                          <ng-container *ngIf="item.question.valueCount > 0">
                            <input
                              name="question{{ i }}"
                              type="checkbox"
                              [checked]="results[i].indexOf(option.value) > -1"
                              [value]="option.value"
                              (click)="
                                handleCheckboxSelect(
                                  i,
                                  option.value,
                                  item.question.valueCount
                                )
                              "
                              id="question{{ i }}option{{ j }}"
                            />
                          </ng-container>
                          <ng-container *ngIf="item.question.valueCount === 0">
                            <input
                              name="question{{ i }}"
                              type="radio"
                              [value]="option.value"
                              (click)="results[i] = option.value"
                              id="question{{ i }}option{{ j }}"
                            />
                          </ng-container>
                          <label
                            class="multiple-label"
                            *ngIf="item.sort == 'multiple'"
                            for="question{{ i }}option{{ j }}"
                            [innerHTML]="labelHtml(option)"
                          ></label>
                        </ng-container>
                        <ng-container *ngIf="item.sort == 'range'">
                          <input
                            name="question{{ i }}"
                            type="radio"
                            [value]="option.value"
                            (click)="results[i] = option.value"
                            id="question{{ i }}option{{ j }}"
                          />
                          <label for="question{{ i }}option{{ j }}">{{
                            option.value ? option.value : option.text
                          }}</label>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-template>
      </ng-container>
    </div>
    <div
      class="questions-holder user-info-holder"
      *ngIf="
        (!isUserLoggedIn || !userHasPhoneNumber) && !survey.isCampaignExpired
      "
    >
      <div class="question">
        <div>
          <div class="question-text">
            <label for="phone"
              >Telefon No: <br /><small
                >(İndirim kodunuzu gönderebilmek için lütfen telefon numaranızı
                başında 0 olmadan girin)</small
              ></label
            >
          </div>
          <div class="question-options">
            <input
              type="text"
              id="phone"
              name="phone"
              [(ngModel)]="phone"
              #ctrl="ngModel"
              maxlength="10"
              title="Lütfen başında 0 olmadan giriniz"
              placeholder="5321231212"
              class="user-info-input"
              (ngModelChange)="onPhoneChange()"
            />
          </div>

          <div
            class="bottoms agreement-box"
            *ngIf="
              !isUserExistsWithPhoneLoading &&
              isRegisterButton &&
              !isUserLoggedIn
            "
          >
            <div class="remember-me kullanimkosullari">
              <input
                type="checkbox"
                id="ticariIletiAgreement"
                [checked]="ticariIletiAgreement"
                (change)="ticariIletiAgreement = !ticariIletiAgreement"
              />
              <label
                for="ticariIletiAgreement"
                style="white-space: break-spaces"
              >
                <a href="/ticari-ileti" target="_blank"
                  >Elektronik Ticari İleti Bilgilendirme Metni</a
                >
                kapsamında Bilgesin tanıtım içeriklerinden haberdar olmak
                istiyorum.
              </label>
            </div>
          </div>

          <div
            class="bottoms agreement-box"
            *ngIf="
              !isUserExistsWithPhoneLoading &&
              isRegisterButton &&
              !isUserLoggedIn
            "
          >
            <div class="remember-me kullanimkosullari">
              <input
                type="checkbox"
                id="openConsentAgreement"
                [checked]="openConsentAgreement"
                (change)="openConsentAgreement = !openConsentAgreement"
              />
              <label
                for="openConsentAgreement"
                style="white-space: break-spaces"
              >
                <a href="/pazarlama-acik-riza" target="_blank"
                  >Bilgesin Pazarlama Faaliyetleri Açık Rıza Metni</a
                >
                kapsamında anket bilgilerimin pazarlama ve tanıtım amacıyla
                işlenmesine onay veriyorum.
              </label>
            </div>
          </div>
        </div>
      </div>
      <ng-template [ngIf]="!isUserExistsWithPhoneLoading">
        <div class="question" *ngIf="phoneCorrect && !isRegisterButton">
          <div>
            <div class="question-text">
              <label for="phonePass">
                Şifre
                <small
                  style="font-size: 0.8rem; font-weight: 100; display: inline"
                >
                  <ng-template [ngIf]="isUserExistsWithPhone">
                    (Telefonunuza daha önce gelen 5 haneli şifreyi girin.
                    Şifreyi hatırlamıyorsanız «Şifremi Unuttum» sekmesini
                    tıklayın.)
                  </ng-template>
                  <ng-template [ngIf]="!isUserExistsWithPhone">
                    (Telefonunuza gelen 5 haneli şifreyi girin.)
                  </ng-template>
                </small>
              </label>
            </div>
            <div class="question-options">
              <app-password-input
                [(model)]="phonePass"
                name="phonePass"
                [survey]="true"
                id="phonePass"
                [landingPageKey]="landingPageKey"
              ></app-password-input>

              <app-button
                type="link link-orange survey-forgot-password {{
                  landingPageKey
                }}"
                text="Şifremi Unuttum"
                (click)="showResetPassword = true"
              ></app-button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
    <div class="finish-survey-holder" *ngIf="!survey.isCampaignExpired">
      <app-button
        [type]="landingPageKey || 'simple'"
        text="Kayıt Ol"
        (click)="finishSurvey()"
        *ngIf="isRegisterButton"
      ></app-button>
      <app-button
        [text]="landingPageKey ? 'Araştırmayı Bitir' : 'Anketi Bitir'"
        [type]="landingPageKey || 'simple'"
        (click)="!finishSurveyWorking ? finishSurvey() : undefined"
        [disabled]="finishSurveyWorking"
        *ngIf="!isRegisterButton"
      ></app-button>
    </div>
    <div class="register-agreement" *ngIf="isRegisterButton">
      Kayıt Ol’a basarak Bilgesin
      <a href="/kullanim-kosullari" target="_blank">Kullanım Koşullarını</a>
      onayladığınız ve KVKK
      <a href="/kisisel-verilerin-korunmasi" target="_blank"
        >Gizlilik ve Çerez Politikasını</a
      >
      okuyup, anladığınızı kabul edersiniz.
    </div>
  </div>
</div>

<app-reset-password
  [landingPageKey]="landingPageKey"
  [show]="showResetPassword"
  (hide)="hideResetPassword($event)"
  [phone]="phone"
></app-reset-password>

<app-product-links-modal
  [(visible)]="productLinksModalShowing"
  [companyId]="survey?.companyId"
></app-product-links-modal>
