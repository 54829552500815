import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import * as moment from "moment";
import { take } from "rxjs/operators";
import {
  boostOxygenCompanyId,
  boostOxygenDiscountCode,
  estmarineCompanyId,
  estmarineDiscountCode,
  leoparCompanyId,
  leoparDiscountCode,
  muhikuCompanyId,
  muhikuDiscountCode,
  pickhobiCompanyId,
  pickhobiDiscountCode,
  sportiveCompanyId,
} from "src/app/helpers/helpers";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-user-earnings",
  templateUrl: "./user-earnings.component.html",
  styleUrls: ["./user-earnings.component.scss"],
})
export class UserEarningsComponent implements OnInit {
  earnings = [];
  earningsLoading = false;
  breadcrumbData = [
    { name: "Anasayfa", href: "/" },
    { name: "Kazanımlarım", active: true },
  ];

  showQrModal = false;
  selected: any;

  sportiveCompanyId = sportiveCompanyId;

  constructor(
    private _sanitizer: DomSanitizer,
    private http: HttpClient,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.user.subscribe((res) => {
      if (res) {
        this.getEarnings();
      }
    });
  }

  getEarnings() {
    this.earningsLoading = true;
    this.earnings = [];

    this.http
      .get("/get-user-earnings")
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          this.earnings = res;
          this.earningsLoading = false;
        },
        (err: any) => {
          this.earningsLoading = false;
        }
      );
  }

  getStatus(item) {
    if (item.discountState === "USED") {
      return "İNDİRİM KULLANILDI";
    }

    if (item.campaignExpireDate) {
      const diff = moment(item.campaignExpireDate).diff(moment(), "d");
      if (diff < 0) {
        return "KAMPANYA TARİHİ GEÇMİŞ";
      }
    }

    if (item.discountState === "NOT_USED") {
      return "İNDİRİM KULLANILABİLİR";
    }
  }

  openEarningQr(item) {
    this.selected = item;
    this.showQrModal = true;
  }

  getBackground(qr) {
    return this._sanitizer.bypassSecurityTrustStyle(`url(${qr})`);
  }

  getGiftFontClass(item) {
    if (item.discountDescription?.length > 13) {
      return "curvy desc-small";
    }

    return "curvy";
  }

  discountCode(item) {
    if (item.companyId === pickhobiCompanyId) {
      return pickhobiDiscountCode;
    } else if (item.companyId === estmarineCompanyId) {
      return estmarineDiscountCode;
    } else if (item.companyId === boostOxygenCompanyId) {
      return boostOxygenDiscountCode;
    } else if (item.companyId === leoparCompanyId) {
      return leoparDiscountCode;
    } else if (item.companyId === muhikuCompanyId) {
      return muhikuDiscountCode;
    }

    return item.discountCode;
  }
}
